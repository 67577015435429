export const colorOptions = [
  { label: "Red", value: "red" },
  { label: "Blue", value: "blue" },
  { label: "Green", value: "green" },
  { label: "Yellow", value: "yellow" },
  { label: "Black", value: "black" },
  { label: "White", value: "white" },
  { label: "Gray", value: "gray" },
  { label: "Cyan", value: "cyan" },
  { label: "Magenta", value: "magenta" },
  { label: "Orange", value: "orange" },
  { label: "Purple", value: "purple" },
  { label: "Teal", value: "teal" },
  { label: "Maroon", value: "maroon" },
  { label: "Navy", value: "navy" },
  { label: "Turquoise", value: "turquoise" },
  { label: "Silver", value: "silver" },
  { label: "Lime", value: "lime" },
  { label: "Pink", value: "pink" },
  { label: "Brown", value: "brown" },
  { label: "Beige", value: "beige" },
  { label: "Mint", value: "mint" },
  { label: "Olive", value: "olive" },
  { label: "Coral", value: "coral" },
  { label: "Nude", value: "nude" },
  { label: "Gold", value: "gold" },
  { label: "Peach", value: "peach" },
  { label: "Lavender", value: "lavender" },
  { label: "Indigo", value: "indigo" },
  { label: "Champagne", value: "champagne" },
  { label: "Salmon", value: "salmon" },
];
